
import { apiPost } from "@/lib/api_post";
import { MyCandidate, MyPostDetail, PostForm } from "@/models/post";
import { userStore } from "@/store/user";
import { defineComponent, reactive, onMounted, Ref, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import GoBackButton from "@/components/GoBackButton.vue";
import CandidateSelector from "@/components/CandidateSelector.vue";
import ComponentPostForm from "@/components/PostForm.vue";
import { Toast } from "@/lib/toast";
import { copyTextToClipboard } from "@/lib/utils";
export default defineComponent({
  name: "ViewPostDetail",
  components: { GoBackButton, CandidateSelector, ComponentPostForm },
  setup() {
    const route = useRoute();
    const id: string = route.params.id as string;
    const state = reactive({
      isLoading: false,
      isCandidateSelectable: false,
      isEditing: false,
    });
    const postDetail: Ref<MyPostDetail> = ref(new MyPostDetail());
    const postForm: Ref<PostForm> = ref(new PostForm());
    const init = async () => {
      state.isLoading = true;
      await getPostDetail();
      postForm.value = postDetail.value.toPostForm();
      state.isLoading = false;
    };
    const getPostDetail = async () => {
      const res = await apiPost.getPostDetail(
        Number(id),
        userStore.ginToken.value
      );
      postDetail.value = new MyPostDetail(res.getInfo());
      const activityList = await apiPost
        .getActivityList(userStore.ginToken.value)
        .then((res) => res.getItemsList());
      postDetail.value.activity.name =
        activityList
          .find((e) => e.getId() === postDetail.value.activity.id)
          ?.getName() || "-";
    };

    const onSelectCandidates = async (candidates: MyCandidate[]) => {
      try {
        const res = await apiPost.updatePostCandidateList(
          {
            postId: postDetail.value.id,
            candidateList: candidates
              .map((e) => {
                e.price = postDetail.value.price;
                return e;
              })
              .map((e) => e.toCandidate()),
          },
          userStore.ginToken.value
        );
        Toast.info("指定人選變更成功");
      } catch (error) {
        Toast.bugOccur();
      }
    };

    const toggleEdit = (bool?: boolean) => {
      if (typeof bool === "boolean") {
        state.isEditing = bool;
      } else {
        state.isEditing = !state.isEditing;
      }
    };

    onMounted(async () => {
      init();
    });

    const onSubmit = async (postForm: PostForm) => {
      try {
        state.isEditing = false;
        Toast.info("通告更新成功");
      } catch (error) {
        Toast.info("通告更新失敗");
      }
    };

    const copyRegisterUrl = () => {
      copyTextToClipboard(postDetail.value.registerUrl);
    };

    const copy = (text: string) => {
      copyTextToClipboard(text);
      Toast.info("複製成功");
    };
    return {
      postDetail,
      onSelectCandidates,
      ...toRefs(state),
      toggleEdit,
      postForm,
      onSubmit,
      copyRegisterUrl,
      copy,
    };
  },
});
