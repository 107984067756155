
import { defineComponent, reactive, toRefs, watch, onMounted } from "vue";
import { debounce } from "lodash";
import { apiCompany } from "@/lib/api_gin";
import { userStore } from "@/store/user";
import { apiPost } from "@/lib/api_post";
import { MyCandidate } from "@/models/post";

export default defineComponent({
  name: "CandidateSelector",
  emits: ["onSelect:candidates"],
  props: {
    allowAddLady: {
      default: false,
      type: Boolean,
    },
    postId: {
      required: true,
      type: Number,
    },
  },
  setup(props, { emit }) {
    const __state: {
      optionLadyList: MyCandidate[];
      optionIds: string[];
      ladyList: MyCandidate[];
      isFetching: boolean;
      isLoading: boolean;
      hasChange: boolean;
    } = {
      optionLadyList: [],
      optionIds: [],
      ladyList: [],
      isFetching: false,
      isLoading: false,
      hasChange: false,
    };
    const state = reactive(__state);

    const fetchLadyList = debounce(async (search: string) => {
      state.optionLadyList = [];
      if (search) {
        state.isFetching = true;
        const res = await apiCompany.getStarList(
          {
            page: 1,
            limit: 1000,
            name: search,
            status: true,
            isMine: false,
          },
          userStore.ginToken.value
        );
        state.optionLadyList = res.getDataList().map(
          (e) =>
            new MyCandidate({
              uid: e.getUid(),
              objectId: e.getObjectId(),
              name: e.getDisplayName(),
              avatar: e.getAvatarUrl(),
            })
        );
        state.isFetching = false;
      }
    }, 300);

    const handleChange = (ids: string[]) => {
      ids.map((id) => addLady(id));
    };

    const getCandidateList = async () => {
      const res = await apiPost.getPostCandidateList(
        { postId: props.postId },
        userStore.ginToken.value
      );
      return res.getCandidatesList().map((e) => new MyCandidate(e));
    };
    const onBeforeUnload = () => {
      if (state.hasChange) {
        alert("人選變更尚未儲存，確定離開？");
      }
    };

    onMounted(async () => {
      state.isLoading = true;
      state.ladyList = await getCandidateList();
      state.isLoading = false;
      window.onbeforeunload = onBeforeUnload;
    });
    const addLady = (objectId: string) => {
      const option = state.optionLadyList.find((e) => e.objectId === objectId);
      if (option && !state.ladyList.find((e) => e.uid === option.uid)) {
        state.ladyList.push(option);
        option.isSelected = true;
        // state.hasChange = true;
        submit();
      }
      state.optionIds = [];
    };

    const onSelect = (lady: MyCandidate) => {
      lady.isSelected = !lady.isSelected;
      // state.hasChange = true;
      submit();
    };

    const submit = () => {
      emit("onSelect:candidates", state.ladyList);
    };

    return {
      ...toRefs(state),
      fetchLadyList,
      handleChange,
      addLady,
      onSelect,
      allowAddition: props.allowAddLady,
      submit,
    };
  },
});
